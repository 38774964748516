import React, { Component, useEffect } from "react";
import Manager from "./Containers/Manager/Manager.jsx";
// import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";
import { init as initMeta } from "./Redux/Actions/metaActions";
import { init as initParts } from "./Redux/Actions/partActions";
// import * as actionTypes from "./Redux/Actions/actionsTypes";
// import {
//   doorTypes as ApiDoorTypes,
//   partTypes as ApiPartTypes,
//   ApiDoorSerieses,
// } from "./Scripts/ApiManager.js";

import { useDispatch, useSelector } from "react-redux";

import { setUser } from "./Redux/Actions/userActions.js";
import auth from "./Scripts/Auth";
import Login from "./Components/login/Login.jsx";
import { NotificationContainer } from "react-notifications";

import "react-notifications/lib/notifications.css";
import "semantic-ui-css/semantic.rtl.min.css";

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  useEffect(() => {
    dispatch(initMeta());
    dispatch(initParts());
    if (auth.isAuth()) dispatch(setUser());
  }, []);

  if (!user.loggedIn) return <Login />;
  return (
    <div>
      <Manager />
      <NotificationContainer />
    </div>
  );
}
// class App extends Component {
//   componentDidMount() {
//     Promise.all([ApiDoorTypes(), ApiPartTypes(), ApiDoorSerieses()]).then(
//       (data) => {
//         this.props.dispatch({
//           type: actionTypes.INIT,
//           payload: {
//             doorTypes: data[0],
//             partTypes: data[1],
//             doorSerieses: data[2].map(({ series_name, door_type }) => ({
//               series_name,
//               door_type,
//             })),
//           },
//         });
//       }
//     );
//   }
//   render() {
//     return <Manager />;
//   }
// }
// const mapDispatchToProps = (dispatch) => ({
//   init: () => dispatch(metaActions.init()),
//   dispatch,
// });
// export default connect(
//   null,
//   mapDispatchToProps
// )(withRouter((props) => <App {...props} />));
export default App;
