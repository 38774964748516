import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import LogOut from "../../LogOut/LogOut";
import Classes from "./Navbar.module.css";
const routes = [
  {
    route: "/hub/item",
    label: "עריכה",
  },
  {
    route: "/Parts",
    label: "פריטים",
  },
  // {
  //   route: "/Orders",
  //   label: "הזמנות",
  // },
  {
    route: "/Clients",
    label: "לקוחות",
  },
];
function ToolBar() {
  const { user } = useSelector((state) => state.userReducer);

  return (
    <div className={Classes.nav}>
      <div>שלום {user?.name}</div>
      <nav>
        {routes.map((x, i) => (
          <NavLink activeClassName={Classes.active} to={x.route} key={i} end>
            <li className={Classes.navItem}>{x.label}</li>
          </NavLink>
        ))}
      </nav>
      <div role="button">
        <LogOut>התנתק</LogOut>
      </div>
    </div>
  );
}

export default ToolBar;
