import React, { useEffect, useRef, useState } from "react";
import classes from "./Resizer.module.scss";

function Resizer(
  { value = {}, onChange, image, dumbStyle = {}, children, disabled },
  parentRef
) {
  const [size, setSize] = useState({
    width: value.width || 0.2,
    height: value.height || 0.2,
  });

  //translation is calculated by the amount moved from the parent 0,0 values of 0-1
  const [translation, setTranslation] = useState({
    left: value.left || 0,
    top: value?.top || 0,
  });

  const dragOffset = useRef({ x: 0, y: 0 });
  const dragging = useRef(false);

  const resizerHandleRef = useRef();
  const resizing = useRef();
  const itemRef = useRef();
  useEffect(() => {
    setTranslation({ top: value.top || 0, left: value.left || 0 });
    setSize({ height: value.height || 0.2, width: value.width || 0.2 });
  }, [value]);

  const cancleAll = () => {
    if (!dragging.current && !resizing.current) return;
    dragging.current = false;
    resizing.current = false;
    dragOffset.current = { x: 0, y: 0 };
    // console.log(translation);
    onChange({ ...size, ...translation });
  };

  useEffect(() => {
    const { current } = parentRef;
    if (!current) return;
    current.addEventListener("mousemove", mouseMove);
    current.addEventListener("mouseup", cancleAll);
    current.addEventListener("mouseleave", cancleAll);
    // parentRef.current.parentElement.addEventListener("mouseup", cancleAll);

    return () => {
      current.removeEventListener("mousemove", mouseMove);
      current.removeEventListener("mouseup", cancleAll);
      current.removeEventListener("mouseleave", cancleAll);
      // parentRef.current.parentElement.removeEventListener("mouseup", cancleAll);
    };
  }, [cancleAll]);

  const mouseDown = (e) => {
    dragging.current = true;
    const { x: itemX, y: itemY } = itemRef.current.getBoundingClientRect();
    // console.log(e.pageY, itemY);
    //Get the offset of the mouse click from the top left corner of the element
    const offsetX = e.pageX - itemX;
    const offsetY = e.pageY - window.scrollY - itemY;
    dragOffset.current = { x: offsetX, y: offsetY };
  };
  const mouseMove = (e) => {
    //only care about movment when dragging
    if (dragging.current) translate(e);
    if (resizing.current) resize(e);
  };
  const translate = (e) => {
    const {
      x: parentX,
      y: parentY,
      width: parentWidth,
      height: parentHeight,
    } = parentRef.current.getBoundingClientRect();

    //get relative positon to parent
    const relativeX = e.clientX - parentX;
    const relativeY = e.clientY - parentY;

    //get fraction from top left of parent container
    let xOff = (relativeX - dragOffset.current.x) / parentWidth;
    let yOff = (relativeY - dragOffset.current.y) / parentHeight;
    // console.log(dragOffset.current.y);
    const lim = (val, min, max) => (val > max ? max : val < min ? min : val);

    //limit to the bounderies of the parent
    xOff = lim(
      xOff,
      0,
      1 - itemRef.current.getBoundingClientRect().width / parentWidth
    );

    yOff = lim(
      yOff,
      0,
      1 - itemRef.current.getBoundingClientRect().height / parentHeight
    );
    setTranslation({ left: xOff, top: yOff });
  };

  const resize = (e) => {
    const { x: itemX, y: itemY } = itemRef.current.getBoundingClientRect();
    //divide by parent dimention to normlize the value 0-1 to make it relative
    const dx =
      (e.clientX - itemX) / parentRef.current.getBoundingClientRect().width;
    const dy =
      (e.clientY - itemY) / parentRef.current.getBoundingClientRect().height;

    setSize({ width: dx, height: dy });
  };
  return (
    <div
      className={classes.item + " " + (disabled && classes.disabled)}
      onMouseDown={mouseDown}
      onMouseUp={cancleAll}
      ref={itemRef}
      style={{
        top:
          translation.top * parentRef.current?.getBoundingClientRect().height,
        left:
          translation.left * parentRef.current?.getBoundingClientRect().width,
        width: size.width * parentRef.current?.getBoundingClientRect().width,
        height: size.height * parentRef.current?.getBoundingClientRect().height,
        ...dumbStyle,
      }}
    >
      {children}
      <div
        ref={resizerHandleRef}
        className={classes.resizer}
        onMouseDown={(e) => {
          e.stopPropagation();
          resizing.current = true;
        }}
        onMouseUp={cancleAll}
      />
      {image && <img src={image} draggable={false}></img>}
    </div>
  );
}
export default React.forwardRef(Resizer);
