import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import ListSelector from "../listSelector/ListSelector";
import ParamForm from "../ParamForm/ParamForm";
import classes from "./paramManager.module.scss";
import {
  addParam as apiAddParam,
  updateParam as apiUpdateParam,
} from "../../API/paramCRUD";

import {
  updateParam as updateParamAction,
  addParam as addParamAction,
} from "../../Redux/Actions/metaActions";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
export default function ParamManager() {
  const [showAddParamModal, setShowAddParamModal] = useState(false);
  const [selectedParam, setSelectedParam] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const { params } = useSelector((state) => state.metaReducer);
  const dispatch = useDispatch();
  const addParam = (e) => {
    setIsloading(true);
    apiAddParam(e).then(({ data }) => {
      if (data) {
        dispatch(addParamAction(data));
        NotificationManager.success("הוספת פרמטר", "הפרמטר נוסף בהצלחה");
        setShowAddParamModal(false);
      } else {
        NotificationManager.error("הוספת פרמטר", "נכשל להוסיף את הפרמטר");
      }
      setIsloading(false);
    });
  };
  const updateParam = (e) => {
    setIsloading(true);

    apiUpdateParam(e, selectedParam.id).then(({ data }) => {
      if (data) {
        dispatch(updateParamAction(data));
        NotificationManager.success("עריכת פרמטר", "הפרמטר נערך בהצלחה");
        setShowAddParamModal(false);
      } else {
        NotificationManager.success("עריכת פרמטר", "נכשל לערוך את הפרמטר");
      }
      setIsloading(false);
    });
  };
  return (
    <div className={classes.container}>
      <Modal
        open={showAddParamModal}
        onClose={() => setShowAddParamModal(false)}
        centered={false}
      >
        <Modal.Header>הוסף סוג</Modal.Header>
        <Modal.Content>
          <ParamForm
            action={{ text: "הוסף", onClick: addParam }}
            loading={isLoading}
          />
        </Modal.Content>
      </Modal>
      <aside>
        <ListSelector
          items={params}
          onChange={setSelectedParam}
          selected={selectedParam}
          addItem={() => setShowAddParamModal(true)}
        />
      </aside>
      <main>
        <ParamForm
          key={selectedParam.id}
          edit
          initValue={selectedParam}
          loading={isLoading}
          action={{ text: "עדכן", onClick: updateParam }}
        />
      </main>
    </div>
  );
}
