import { apiGet } from "../../Scripts/Api";
import * as actions from "./actionsTypes";

export const init = () => async (dispatch) => {
  const { data: parts } = await apiGet("parts/all");

  dispatch({ type: actions.SET_PARTS, payload: parts });
};
export const addPart = (part) => {
  return { type: actions.ADD_PART, payload: part };
};
export const updatePart = (part) => {
  return { type: actions.UPDATE_PART, payload: part };
};
export const deletePart = (id) => {
  return { type: actions.DELETE_PART, payload: id };
};
