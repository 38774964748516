import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "semantic-ui-react";
import ItemFilter from "../ItemFilter/ItemFilter";
import classes from "./PartBrowser.module.scss";
export default function PartBrowser({ selected = [], onChange }) {
  const [filter, setFilter] = useState({ type: [], cat: "" });
  const { parts } = useSelector((state) => state.partReducer);
  const clicked = (x) => {
    if (selected.includes(x.catNumber)) onChange(-1, x.catNumber);
    else onChange(1, x.catNumber);
  };
  return (
    <div className={classes.container}>
      <ItemFilter filter={filter} onChange={setFilter} />
      <div className={classes.wraper}>
        {parts
          .filter((x) => selected.includes(x.catNumber))
          .map((x) => (
            <div
              className={
                classes.card +
                " " +
                (selected.includes(x.catNumber) && classes.selected)
              }
              onClick={() => clicked(x)}
            >
              <img src={x.image} alt={x.catNumber} />
              <p>{x.catNumber}</p>
            </div>
          ))}
        {parts
          .filter(
            (x) =>
              x.catNumber.match(filter.cat) &&
              (filter.type.length === 0
                ? true
                : filter.type.includes(x.type)) &&
              !selected.includes(x.catNumber)
          )
          .map((x) => (
            <div
              className={
                classes.card +
                " " +
                (selected.includes(x.catNumber) && classes.selected)
              }
              onClick={() => clicked(x)}
            >
              <img src={x.image} alt={x.catNumber} />
              <p>{x.catNumber}</p>
            </div>
          ))}
      </div>
    </div>
  );
}
