import React from "react";
import classes from "./Hub.module.css";
import { Link, Route, Switch } from "react-router-dom";
import AddPart from "../../Components/AddPart/AddPart";

import TypeManager from "../../Components/TypeManager/TypeManager";
import ParamManager from "../../Components/ParamManager/ParamManager";
import StageManager from "../../Components/StageManager/StageManager";
import ViewManager from "../../Components/Viewmanager/ViewManager";

export default function Hub() {
  return (
    <div className={classes.container}>
      <nav>
        <Link to="/hub/item">Add</Link>|<Link to="/hub/type">Types</Link>|
        <Link to="/hub/params">Params</Link>|
        <Link to="/hub/stages">Stages</Link>|
        <Link to="/hub/configurator">Configurator</Link>
      </nav>
      <Switch>
        <Route path="/hub/item" exact>
          <AddPart />
        </Route>
        <Route path="/hub/type" exact>
          <TypeManager />
        </Route>
        <Route path="/hub/params" exact>
          <ParamManager />
        </Route>
        <Route path="/hub/stages" exact>
          <StageManager />
        </Route>
        <Route path="/hub/configurator" exact>
          <ViewManager />
        </Route>
      </Switch>
    </div>
  );
}
