const url = `${process.env.REACT_APP_API_URL}/api/parts`;
const clientUrl = `${process.env.REACT_APP_API_URL}/api/client`;
export const getPart = async (id) => {
  const data = await fetch(`${url}/all/${id}`)
    .then((rawData) => rawData.json())
    .catch((x) => console.log("cant get data"));
  return data;
};

export const partTypes = async () => {
  const data = await fetch(`${url}/partTypes`)
    .then((rawData) => rawData.json())
    .catch((x) => console.log("cant get data"));
  return data;
};

export const catInUse = async (cat) => {
  if (!cat) return false;
  const { data } = await fetch(`http://localhost:3000/parts/taken/${cat}`).then(
    (rawData) => rawData.json()
  );

  return data.taken;
};

export const getPreviewImages = (type) => {
  return fetch(`${url}/previews/${type}`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth")}`,
    },
  }).then((res) => res.json());
};

export const deletePreviewImage = (id) => {
  return fetch(`${process.env.REACT_APP_API_URL}/parts/preview/delete/${id}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth")}`,
    },
  }).then((res) => res.json());
};
