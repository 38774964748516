import { uploadImage } from "../Scripts/upload";

const processObj = async (obj) => {
  let img = "";

  if (obj.image instanceof File) {
    const { data, err } = await uploadImage(obj.image);
    if (err) return Promise.reject(err);
    img = data;
    obj.image = img;
  }
};
export const addType = async (obj) => {
  await processObj(obj);
  const data = await fetch(`${process.env.REACT_APP_API_URL}/parts/types/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth")}`,
    },
    body: JSON.stringify(obj),
  }).then((rawData) => rawData.json());
  return data;
};
export const upateType = async (obj, id) => {
  await processObj(obj);
  const data = await fetch(
    `${process.env.REACT_APP_API_URL}/parts/types/update/${id}`,
    {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify(obj),
    }
  ).then((rawData) => rawData.json());
  return data;
};
