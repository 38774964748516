export const uploadImage = (image) => {
  const fd = new FormData();
  fd.append("image", image);
  return fetch(`${process.env.REACT_APP_API_URL}/upload/image`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth")}`,
    },
    body: fd,
  }).then((res) => res.json());
};
export const uploadPreview = (image, name, type) => {
  const fd = new FormData();
  fd.append("image", image);
  fd.append("name", name);
  fd.append("type", type);
  return fetch(`${process.env.REACT_APP_API_URL}/upload/preview`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth")}`,
    },
    body: fd,
  }).then((res) => res.json());
};
