import React, { useState } from "react";
import PartForm from "../PartForm/PartForm";
import api from "../../API/api";
import { useDispatch } from "react-redux";
import { addPart as addPartAction } from "../../Redux/Actions/partActions";
import { NotificationManager } from "react-notifications";
export default function Addart() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const addPart = (obj) => {
    setLoading(true);
    api.addPart(obj).then(({ data }) => {
      if (data !== undefined) {
        dispatch(addPartAction(data));
        NotificationManager.success("החלק נוסף בהצלחה", "הוספת חלק", 2000);
      } else {
        NotificationManager.error("נכשל להוסיף את החלק", "הוספת חלק", 2000);
      }
      setLoading(false);
    });
  };
  return (
    <div>
      <PartForm
        action={{ text: "הוסף", color: "green", onClick: addPart }}
        defaultOpen={{ primary: 1 }}
        loading={loading}
      />
    </div>
  );
}
