export const updateParam = (e, id) => {
  return fetch(`${process.env.REACT_APP_API_URL}/parts/params/update/${id}`, {
    method: "PATCH",
    body: JSON.stringify(e),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth")}`,
    },
  }).then((res) => res.json());
};
export const addParam = (e) => {
  return fetch(`${process.env.REACT_APP_API_URL}/parts/params/add`, {
    method: "POST",
    body: JSON.stringify(e),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth")}`,
    },
  }).then((res) => res.json());
};
