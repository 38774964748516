import React from "react";
import { useSelector } from "react-redux";
import { Button, Table } from "semantic-ui-react";
import classes from "./DisplayBrowser.module.scss";
export default function DisplayBrowser({ displaySelected }) {
  const { displays } = useSelector((state) => state.metaReducer);
  return (
    <div className={classes.container}>
      <Table celled collapsing compact>
        <Table.Header>
          <Table.HeaderCell>שם</Table.HeaderCell>
          <Table.HeaderCell>פעולות</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {displays.map((x) => (
            <Table.Row>
              <Table.Cell>{x.name}</Table.Cell>
              <Table.Cell>
                <Button
                  icon="edit"
                  onClick={() => displaySelected(x, "Edit")}
                ></Button>
                <Button
                  icon="eye"
                  onClick={() => displaySelected(x, "View")}
                ></Button>
                <Button
                  icon="check"
                  onClick={() => displaySelected(x, "click")}
                ></Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
