import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown, Input, TextArea } from "semantic-ui-react";
import classes from "./ParamForm.module.scss";
const paramTypes = [
  { text: "מספר", value: "number" },
  { text: "רשימה", value: "list" },
];

const parseType = (type) => {
  if (type?.length > 1 || (type && type[0]) !== "number") return paramTypes[1];
  else return paramTypes[0];
};

const parseTypeOptions = (type) => {
  if (!type || (type && type[0]) === "number") return;
  return type.join("\n");
};

export default function ParamForm({ initValue = {}, action, loading, edit }) {
  const { params } = useSelector((state) => state.metaReducer);

  //actuall fields
  const [name, setName] = useState(initValue.name || "");

  //dummy fields, for user abstraction
  const [paramType, setParamType] = useState(
    parseType(initValue.type) || paramTypes[0]
  );
  const [options, setOptions] = useState(
    parseTypeOptions(initValue.type) || ""
  );

  const [condition, setCondition] = useState(
    initValue.condition
      ? {
          ...initValue.condition,
          param: params.find((o) => o.id === initValue.condition.paramId),
        }
      : {}
  );

  const [selectedOperator, setSelectedOpertor] = useState(
    initValue.condition?.test?.range ? "↔" : "="
  );
  const submit = () => {
    const clearedCondition = { ...condition };
    delete clearedCondition.param;
    console.log(options);
    const paramObj = {
      name,
      type:
        paramType.value === "number"
          ? ["number"]
          : options.split("\n").filter((x) => x.length > 0),
      condition: clearedCondition,
    };

    action.onClick(paramObj);
  };

  const getOperatorOptions = () => {
    const options = [{ text: "=", value: "=" }];
    if (condition.param?.type.length === 1)
      options.push({ text: "↔", value: "↔" });
    return options;
  };
  return (
    <div>
      <Input
        label="שם"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Dropdown
        options={paramTypes}
        value={paramType.value}
        selection
        onChange={(e, { value }) =>
          setParamType(paramTypes.find((x) => x.value === value))
        }
        disabled={edit}
      />
      {paramType.value === "list" && (
        <div className={classes.items}>
          <div className={classes.area}>
            <p>
              פריטים <span>(כל פריט בשורה חדשה)</span>
            </p>
            <TextArea
              value={options}
              onChange={(e) => setOptions(e.target.value)}
            />
          </div>
          <ul className={classes.preview}>
            {options.split("\n").map((x) => x && <li>{x}</li>)}
          </ul>
        </div>
      )}
      <div className={classes.condition}>
        <h3>:מופיע כאשר</h3>
        <Dropdown
          options={params.map((x) => ({ text: x.name, value: x.id }))}
          selection
          onChange={(e, { value }) => {
            setSelectedOpertor("=");
            setCondition({
              ...condition,
              paramId: value,
              test: {},
              param: params.find((o) => o.id === value),
            });
          }}
          value={condition.paramId}
        />
        <Dropdown
          button
          options={getOperatorOptions()}
          value={selectedOperator}
          onChange={(e, { value }) => setSelectedOpertor(value)}
        />
        {condition.param?.type.length === 1 ? (
          selectedOperator === "=" ? (
            <Input
              label="ערך"
              value={condition?.test?.equal}
              onChange={(e) => {
                setCondition({ ...condition, test: { equal: e.target.value } });
              }}
            />
          ) : (
            <>
              <Input
                label="מינימום"
                value={condition?.test?.range?.min}
                onChange={(e) => {
                  setCondition({
                    ...condition,
                    test: {
                      range: { ...condition.test?.range, min: e.target.value },
                    },
                  });
                }}
              />
              <Input
                label="מקסימום"
                value={condition?.test?.range?.max}
                onChange={(e) => {
                  setCondition({
                    ...condition,
                    test: {
                      range: { ...condition.test?.range, max: e.target.value },
                    },
                  });
                }}
              />
            </>
          )
        ) : (
          <>
            <Dropdown
              button
              options={condition.param?.type.map((t) => ({
                text: t,
                value: t,
              }))}
              value={condition?.test?.equal}
              onChange={(e, { value }) => {
                setCondition({
                  ...condition,
                  test: {
                    equal: value,
                  },
                });
              }}
            />
          </>
        )}
      </div>
      <br />
      <Button onClick={submit} color={action.color} loading={loading}>
        {action.text}
      </Button>
    </div>
  );
}
