import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Input, Dropdown } from "semantic-ui-react";
import classes from "./DisplayImageManager.module.css";
import { getPreviewImages } from "../../Scripts/ApiManager";
import { useSelector } from "react-redux";
import { deletePreviewImage } from "../../Scripts/ApiManager";
import Delete from "../Delete/Delete";
import { uploadPreview } from "../../Scripts/upload";
export default function DisplayImageManager({ id, selected }) {
  const [activeType, setActiveType] = useState(id);
  const [previewImages, setpreviewImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [partType, setPartType] = useState("");

  const { partTypes } = useSelector((state) => state.metaReducer);

  const inputRef = useRef();
  const [error, setError] = useState({ name: false, partType: false });
  useEffect(() => {
    if (!activeType) return;
    fetch(
      `${process.env.REACT_APP_API_URL}/parts/previews?type=${activeType}`
    ).then((res) => res.json().then(({ data }) => setpreviewImages(data)));
  }, [activeType]);

  const imageSelected = (ev) => {
    ev.persist();
    if (!name) return setError({ partType: false, name: true });
    if (!partType) return setError({ partType: true, name: false });
    if (ev.target.files && ev.target.files[0]) {
      setLoading(true);
      uploadPreview(ev.target.files[0], name, partType.id)
        .then(({ data }) => {
          setActiveType(data.type);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const deletePreview = (id) => {
    deletePreviewImage(id).then(() => {
      fetch(
        `${process.env.REACT_APP_API_URL}/parts/previews?type=${activeType}`
      ).then((res) => res.json().then(({ data }) => setpreviewImages(data)));
    });
  };
  return (
    <div className={classes.container}>
      <nav>
        {partTypes.map((x, i) => (
          <span
            onClick={() => setActiveType(x.id)}
            className={x.id === activeType && classes.active}
            key={i}
          >
            {x.name}
          </span>
        ))}
      </nav>

      <div className={classes.table}>
        <Table celled striped textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>שם</Table.HeaderCell>
              <Table.HeaderCell>תמונה</Table.HeaderCell>
              <Table.HeaderCell>מחק</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {previewImages.map((x, i) => (
              <Table.Row>
                <Table.Cell onClick={() => selected(x.url)}>
                  {x.name}
                </Table.Cell>
                <Table.Cell>
                  <img src={x.url} alt={x.name} />
                </Table.Cell>
                <Table.Cell>
                  <Delete confirm={() => deletePreview(x.id)} key={i} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <input ref={inputRef} type="file" hidden onChange={imageSelected} />
      <div className={classes.form}>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={error.name}
          disabled={loading}
        />
        <Dropdown
          disabled={loading}
          error={error.partType}
          text={partType.name || "סוג חלק"}
          icon="wrench"
          labeled
          button
          className="icon"
          value={partType.id}
          options={partTypes.map((x) => ({
            text: x.name,
            value: x.id,
          }))}
          onChange={(e, { value }) =>
            setPartType({
              id: value,
              name: partTypes.find((p) => p.id === value).name,
            })
          }
        />
        <Button
          color="green"
          onClick={() => inputRef.current.click()}
          loading={loading}
        >
          הוסף חדש
        </Button>
      </div>
    </div>
  );
}
