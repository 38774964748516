import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown, Input, Label, Modal } from "semantic-ui-react";

import UploadImage from "../../Components/UI/UploadImage/UploadImage";
import classes from "./PartForm.module.css";

// import Parts from "../../Containers/Parts/Parts";
import { catInUse, partTypes } from "../../Scripts/ApiManager";
import Parameters from "./Parametes/Parametes";
import Collapsable from "../Layout/Collapsable/Collapsable";
import Relationships from "./Relationships/Dependencies";

import DisplayEditor from "./DisplayEditor/DisplayEditor";
import Display from "./Display/Display";

function PartForm({ initValue = {}, action, edit, loading, defaultOpen = {} }) {
  const meta = useSelector((state) => state.metaReducer);

  const [partType, setPartType] = useState(
    meta.partTypes.find((x) => x.id === initValue.type) || {}
  );
  const [cat, setCat] = useState(initValue.catNumber || "");
  const [price, setPrice] = useState(initValue.price || 0);
  const [link, setLink] = useState(initValue.link || "");
  const [defaultAmount, setDefaultAmount] = useState(
    initValue.defaultAmount || 1
  );

  const [conditions, setConditions] = useState(initValue.conditions || []);
  const [relations, setRelations] = useState(initValue.relationships || {});
  const [img, setImg] = useState(initValue.image || "");
  const [desc, setDesc] = useState(initValue.description || "");

  const [display, setDisplay] = useState(initValue.display || {});
  // const [display, setDisplay] = useState({
  //   image:
  //     "https://res.cloudinary.com/hfr9upkxm/image/upload/v1615119331/ulfo1x6yaf9i0pghewkx.png",
  //   preview: {
  //     visible: true,
  //     items: [{ top: 0, left: 0, width: 1, height: 0.5 }],
  //   },
  // });
  const [isCatTaken, setCatInUse] = useState(false);

  useEffect(() => {
    // setDisplay(partType.display);
  }, [partType]);

  // useEffect(() => {
  //   if (Object.keys(initValue).length === 0) return;
  //   setPartType({ id: initValue.part_type, part_name: initValue.type });
  //   setCat(initValue.cat_num || "");
  //   setPrice(initValue.price);
  //   setLink(initValue.part_link || "");
  //   setDesc(initValue.description || "");

  //   setImg(initValue.image_url);
  // }, []);

  useEffect(() => {
    if (!edit) catInUse(cat).then(setCatInUse);
  }, [cat]);

  const submit = () => {
    const partObject = {
      catNumber: cat,
      price,
      type: partType,
      description: desc,
      link,
      defaultAmount,
      display: display.id,
      relations,
      conditions,
      image: img,
    };
    action.onClick(partObject);
  };

  const partTypeChanged = (value) => {
    const type = meta.partTypes.find((x) => x.id === value);
    setPartType(type);

    // if (type.display) setDisplay(type.display);
    // else setDisplay(undefined);
  };
  const deletePart = () => {
    action.delete(initValue.id);
  };
  return (
    <div className={classes.container}>
      {loading && <div className={classes.blocker} />}
      <Collapsable title="מידע עיקרי" defaultOpen={defaultOpen.primary}>
        <div className={classes.field}>
          <Dropdown
            scrolling
            text={partType.name || "סוג חלק"}
            icon="wrench"
            labeled
            button
            className="icon"
            value={partType.id}
            options={meta.partTypes.map((x) => ({
              text: x.name,
              value: x.id,
            }))}
            onChange={(e, { value }) => partTypeChanged(value)}
            // disabled={edit}
          />
        </div>
        <div className={classes.field}>
          <Input
            label='מק"ט'
            value={cat}
            onChange={({ target: { value } }) => setCat(value)}
            // disabled={edit}
            error={isCatTaken}
          />
        </div>
        <div className={classes.field}>
          <Input
            label="מחיר"
            type="number"
            value={price}
            onChange={({ target: { value } }) => setPrice(value)}
          />
        </div>
        <div className={classes.field}>
          <Input
            label="קישור"
            value={link}
            onChange={({ target: { value } }) => setLink(value)}
          />
        </div>
        <div className={classes.field}>
          <Input
            label="כמות דיפולטיבית"
            value={defaultAmount}
            onChange={({ target: { value } }) => setDefaultAmount(value)}
          />
        </div>
      </Collapsable>
      <Collapsable title="פרמטרים" defaultOpen={defaultOpen.params}>
        <Parameters
          onChange={(e) => setConditions(e)}
          conditions={conditions}
        />
      </Collapsable>
      <Collapsable title="תלותיות" defaultOpen={defaultOpen.dependencies}>
        <Relationships
          relations={relations}
          onChange={(dep) => setRelations(dep)}
        />
      </Collapsable>
      <Collapsable title="מדיה" defaultOpen={defaultOpen.media}>
        <div className={classes.field}>
          <Label pointing="below" size="big">
            תמונת מוצר
          </Label>
          <UploadImage value={img} onChange={setImg} />
        </div>
        <div className={classes.field}>
          <div className={classes.desc}>
            <Label pointing="below" size="big">
              תיאור
            </Label>
            <textarea value={desc} onChange={(e) => setDesc(e.target.value)} />
          </div>
        </div>
        <div className={classes.field}>
          <div>
            <Label pointing="below" size="big">
              תצוגה
            </Label>
          </div>
          {/* <DisplayEditor
            key={partType.name}
            type={partType}
            value={display}
            onChange={setDisplay}
          /> */}
          <Display value={display} onChange={setDisplay} key={display?._id} />
        </div>
      </Collapsable>
      <div>
        <Button loading={loading} onClick={submit} disabled={isCatTaken}>
          {action.text}
        </Button>
        {edit && (
          <Button icon="trash" color="red" onClick={deletePart}></Button>
        )}
      </div>
    </div>
  );
}

export default PartForm;
