import React, { useState } from "react";
import classes from "./ParamCondition.module.scss";
import { Button, Dropdown, Input, TextArea } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function ParamCondition({ condition, onChange }) {
  const { params } = useSelector((state) => state.metaReducer);
  const [selectedOperator, setSelectedOperator] = useState("=");

  //   const [condition, setCondition] = useState(condition || {});
  useEffect(() => {
    if (condition.param) return;
    console.log("asd");
    condition.param = params.find((o) => o.id === condition.paramId);
    onChange({ ...condition });
  }, [condition]);

  const getOperatorOptions = () => {
    const options = [{ text: "=", value: "=" }];

    if (condition.param?.type[0] === "number")
      options.push({ text: "↔", value: "↔" });
    else options.push({ text: "⊆", value: "⊆" });
    return options;
  };

  const paramSelected = (paramId) => {
    //The default operator
    setSelectedOperator("=");

    onChange({
      ...condition,
      paramId: paramId,
      test: {},
      param: params.find((o) => o.id === paramId),
    });
  };
  return (
    <div className={classes.condition}>
      <Dropdown
        placeholder="בחר פרמטר"
        options={params.map((x) => ({ text: x.name, value: x.id }))}
        selection
        onChange={(e, { value }) => {
          paramSelected(value);
        }}
        value={condition.paramId}
      />
      <span />
      <Dropdown
        button
        options={getOperatorOptions()}
        value={selectedOperator}
        onChange={(e, { value }) => setSelectedOperator(value)}
      />
      {condition.param?.type.length === 1 ? (
        selectedOperator === "=" ? (
          <Input
            label="ערך"
            value={condition?.test?.equal}
            onChange={(e) => {
              onChange({ ...condition, test: { equal: e.target.value } });
            }}
          />
        ) : (
          <>
            <Input
              label="מינימום"
              value={condition?.test?.range?.min}
              onChange={(e) => {
                onChange({
                  ...condition,
                  test: {
                    range: { ...condition.test?.range, min: e.target.value },
                  },
                });
              }}
            />
            <Input
              label="מקסימום"
              value={condition?.test?.range?.max}
              onChange={(e) => {
                onChange({
                  ...condition,
                  test: {
                    range: { ...condition.test?.range, max: e.target.value },
                  },
                });
              }}
            />
          </>
        )
      ) : (
        <>
          <Dropdown
            button
            options={condition.param?.type.map((t) => ({
              text: t,
              value: t,
            }))}
            multiple={selectedOperator === "⊆"}
            value={
              selectedOperator === "⊆"
                ? condition?.test?.equal
                : condition?.test?.equal[0]
            }
            onChange={(e, { value }) => {
              onChange({
                ...condition,
                test: {
                  equal: value,
                },
              });
            }}
          />
        </>
      )}
    </div>
  );
}
