import store from "../Redux/store";
import { uploadImage } from "../Scripts/upload";

export const addDisplay = async (display) => {
  try {
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/parts/display/add`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
        body: JSON.stringify(display),
      }
    ).then((rawData) => rawData.json());

    return data;
  } catch {
    return Promise.reject();
  }
};

export const editDisplay = async (id, part) => {
  const data = await fetch(
    `${process.env.REACT_APP_API_URL}/parts/display/update/${id}`,
    {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify(part),
    }
  )
    .then((rawData) => rawData.json())
    .catch((x) => console.log("לא מצליח להתחבר לשרת"));

  return data;
};
