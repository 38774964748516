import React from "react";
import { useState } from "react";
import { Button, Input, Modal } from "semantic-ui-react";
import DisplayBrowser from "../../DisplayBrowser/DisplayBrowser";
import DisplayEditor from "../DisplayEditor/DisplayEditor";
import classes from "./Display.module.scss";
import { addDisplay, editDisplay } from "../../../API/displayCRUD";
import { useDispatch } from "react-redux";
import {
  addDisplay as addDisplayAction,
  editDisplay as editDisplayAction,
} from "../../../Redux/Actions/metaActions";

const modes = { Add: "Add", Edit: "Edit", View: "View" };
export default function Display({ onChange, value }) {
  const [showDisplayManager, setShowDisplayManager] = useState(false);
  const [name, setName] = useState("");
  const [display, setDisplay] = useState({});
  const dispatch = useDispatch();
  const [chosenDisplay, setChosenDisplay] = useState(value || {});
  const [mode, setMode] = useState(modes.Add);
  const submit = () => {
    addDisplay({ name, ...display }).then(({ data, err }) => {
      if (data) dispatch(addDisplayAction(data));
    });
  };

  const edit = () => {
    editDisplay(display.id, {
      name,
      items: display.items,
      previewImage: display.previewImage,
    }).then(({ data, err }) => {
      if (data) dispatch(editDisplayAction(data));
    });
  };
  const selected = (e, mode) => {
    if (mode !== "click") {
      setMode(mode);
      setDisplay(e);
      setName(e.name);
    } else {
      setChosenDisplay(e);
      setShowDisplayManager(false);
      onChange(e);
      clear();
    }
  };
  const clear = () => {
    setMode(modes.Add);
    setDisplay({});
    setName("");
  };
  return (
    <div className={classes.container}>
      <Modal
        open={showDisplayManager}
        onClose={() => {
          clear();
          setShowDisplayManager(false);
        }}
        style={{ textAlign: "right" }}
        centered={false}
      >
        <Modal.Header>
          <h1>ניהול תצוגה</h1>
        </Modal.Header>
        <Modal.Content>
          <div className={classes.main}>
            <div className={classes.new}>
              <Input
                label="שם"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={mode === modes.View}
              />
              <DisplayEditor
                value={display}
                onChange={(e) => {
                  console.log(e);
                  setDisplay(e);
                }}
                key={display.id}
                mode={mode}
              />
              {mode === modes.Add ? (
                <Button onClick={submit}>הוסף</Button>
              ) : (
                <div className={classes.btns}>
                  {mode === modes.Edit && <Button onClick={edit}>ערוך</Button>}
                  <Button color="red" onClick={clear}>
                    בטל
                  </Button>
                </div>
              )}
            </div>
            <div className={classes.browser}>
              <DisplayBrowser displaySelected={selected} />
            </div>
          </div>
        </Modal.Content>
      </Modal>
      <Button
        icon="folder open"
        color="green"
        onClick={() => setShowDisplayManager(true)}
      />{" "}
      <span className={classes.name}>{chosenDisplay.name}</span>
    </div>
  );
}
