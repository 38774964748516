import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, Input } from "semantic-ui-react";
import classes from "./ItemFilter.module.scss";
export default function ItemFilter({ filter, onChange = () => {} }) {
  const { partTypes } = useSelector((state) => state.metaReducer);

  return (
    <div className={classes.wraper}>
      <Input
        label="מקט"
        onChange={(e) => onChange({ ...filter, cat: e.target.value })}
        value={filter.cat}
      />
      <div>
        <label>סוג</label>
        <Dropdown
          button
          closeOnChange
          text={filter.type || "בחר"}
          multiple
          scrolling
          value={filter.type}
          onChange={(e, { value }) => onChange({ ...filter, type: value })}
          options={partTypes.map((x) => ({
            key: x.id,
            text: x.name,
            value: x.id,
          }))}
        />
      </div>
    </div>
  );
}
