import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown, Input, Label } from "semantic-ui-react";
import Collapsable from "../Layout/Collapsable/Collapsable";
import ParamCondition from "../ParamCondition/ParamCondition";
import UploadImage from "../UI/UploadImage/UploadImage";
import ViewEditor from "../ViewEditor/ViewEditor";
import classes from "./ViewProfileForm.module.scss";
export default function ViewProfileForm({ action = {}, loading, initValue }) {
  const [conditions, setConditions] = useState(initValue.conditions || [{}]);
  const [name, setName] = useState(initValue.name || "");
  const [image, setImage] = useState(initValue.image || "");
  const [imageDataUrl, setImageDataUrl] = useState(initValue.image || "");
  const [containers, setContainers] = useState(initValue.containers || []);
  const [exclusivity, setExclusivity] = useState(
    initValue.exclusiveTypes || []
  );
  const { partTypes } = useSelector((state) => state.metaReducer);
  const submit = () => {
    action.onClick({
      conditions: conditions.map((x) => {
        const cpy = { ...x };
        delete cpy.param;
        return cpy;
      }),
      name,
      image,
      containers,
      exclusiveTypes: exclusivity,
    });
  };
  // return (

  // );
  return (
    <div className={classes.container}>
      <Input
        label="שם התצוגה"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Collapsable title="תנאי" defaultOpen disabled>
        {/* <h3>תנאי</h3> */}

        <div className={classes.conditions}>
          {conditions.map((x, i) => (
            <div className={classes.condition} key={x.paramId}>
              <Button
                icon="trash"
                onClick={() => {
                  const newConditions = [...conditions];
                  newConditions.splice(i, 1);
                  setConditions(newConditions);
                }}
              />
              <ParamCondition
                condition={x}
                onChange={(con) => {
                  const newConditions = [...conditions];
                  newConditions[i] = con;
                  setConditions(newConditions);
                }}
              />
            </div>
          ))}
          <Button
            icon="plus"
            onClick={() => setConditions([...conditions, {}])}
          />
        </div>
      </Collapsable>
      <Collapsable title="מידע" disabled defaultOpen>
        <div>
          <Label>אקסקלוסיביות</Label>
          <Dropdown
            options={partTypes.map((x, i) => ({ text: x.name, value: x.id }))}
            button
            multiple
            value={exclusivity}
            text={!exclusivity.length && "בחר סוגים"}
            onChange={(e, { value }) => {
              setExclusivity(value);
            }}
          />
        </div>
      </Collapsable>
      <Collapsable title="תצוגה" defaultOpen disabled>
        <div>
          <h3>תמונה </h3>
          <UploadImage
            onChange={setImage}
            value={image}
            getDataURL={setImageDataUrl}
          />
        </div>
        <ViewEditor
          viewImage={imageDataUrl}
          initContrainers={containers}
          onChange={setContainers}
          id={initValue.id}
        />
      </Collapsable>

      <Button loading={loading} onClick={submit}>
        {action.text}
      </Button>
    </div>
  );
}
