import React, { useEffect, useRef, useState } from "react";
import classes from "./PositionEditor.module.scss";
import singleDoor from "../../Assets/Images/singleDoor.png";
import Resizer from "../Resizer/Resizer";

export default function PositionEditor({
  onChange,
  items,
  itemImage,
  disabled,
}) {
  const placerRef = useRef();

  return (
    <div className={classes.container}>
      <div className={classes.placer} ref={placerRef}>
        {items.map((x, i) => (
          <Resizer
            disabled={disabled}
            key={i}
            ref={placerRef}
            value={x}
            onChange={(obj) => onChange(obj, i)}
            image={itemImage}
          />
        ))}
        <img
          className={classes.bg}
          src={singleDoor}
          alt="דלת יחידה"
          draggable={false}
        />
      </div>
    </div>
  );
}
