import React from "react";
import { Button, Icon } from "semantic-ui-react";
import classes from "./ListSelector.module.scss";
export default function ListSelector({
  items,
  selected = {},
  onChange = () => {},
  addItem = () => {},
  compareParam = "id",
}) {
  return (
    <div className={classes.container}>
      <ul className={classes.list}>
        {items.map((x, i) => (
          <li
            key={i}
            className={
              selected[compareParam] === x[compareParam] && classes.selected
            }
            onClick={() => onChange(x)}
          >
            {x.image ? (
              <img src={x.image} alt={x.name} />
            ) : (
              <Icon name="cog" color="grey" />
            )}
            <span>{x.name}</span>
          </li>
        ))}
      </ul>
      <Button icon="plus" onClick={addItem} />
    </div>
  );
}
