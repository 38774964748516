import {
  ADD_PART,
  DELETE_PART,
  SET_PARTS,
  UPDATE_PART,
} from "../Actions/actionsTypes";

const initState = {
  parts: [],
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case SET_PARTS:
      return { ...state, parts: action.payload };
    case ADD_PART:
      return { ...state, parts: [...state.parts, action.payload] };
    case UPDATE_PART: {
      const newParts = [...state.parts];
      const index = newParts.findIndex((x) => x.id === action.payload.id);
      newParts[index] = action.payload;
      return { ...state, parts: newParts };
    }
    case DELETE_PART: {
      const newParts = [...state.parts];
      const index = newParts.findIndex((x) => x.id === action.payload);
      newParts.splice(index, 1);
      return { ...state, parts: newParts };
    }
    default:
      return state;
  }
};
export default reducer;
