export const apiGet = (path) => {
  return fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("auth")}` },
  }).then((res) => res.json());
};
export const apiSet = (path, obj) => {
  return fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),
  }).then((res) => res.json());
};
