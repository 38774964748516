import React, { useState } from "react";
import { useDispatch } from "react-redux";
import api from "../../API/api";
import {
  updatePart,
  deletePart as deletePartAction,
} from "../../Redux/Actions/partActions";
import PartForm from "../PartForm/PartForm";
import { NotificationManager } from "react-notifications";
import * as partCRUD from "../../API/partCRUD";
export default function EditPart({ part, done }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const editPart = (obj) => {
    setLoading(true);

    api.updatePart(part.id, obj).then(({ data }) => {
      if (data) {
        dispatch(updatePart(data));
        NotificationManager.success("עריכת חלק", "החלק נערך בהצלחה");
        done();
      } else {
        NotificationManager.success("עריכת חלק", "נכשל לערוך את החלק");
      }
      setLoading(false);
    });
  };
  const deletePart = (id) => {
    setLoading(true);

    partCRUD.deletePart(id).then(({ data } = {}) => {
      if (data) {
        dispatch(deletePartAction(data));
        NotificationManager.success("עריכת חלק", "החלק נמחק בהצלחה");
        done();
      } else {
        NotificationManager.success("עריכת חלק", "נכשל למחוק את החלק");
      }
      setLoading(false);
    });
  };
  return (
    <PartForm
      action={{
        text: "ערוך",
        color: "green",
        onClick: editPart,
        delete: deletePart,
      }}
      edit
      initValue={part}
      defaultOpen={{ media: 1, primary: 1 }}
      loading={loading}
    />
  );
}
