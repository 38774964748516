import React from "react";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../Redux/Actions/userActions";

export default function LogOut({ children }) {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(logOutUser());
  };
  return (
    <div onClick={logout} style={{ cursor: "pointer" }}>
      {children}
    </div>
  );
}
