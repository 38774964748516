import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import PartBrowser from "../../PartBrowser/PartBrowser";

import classes from "./Dependencies.module.scss";

const options = [
  {
    name: "תלות",
    type: "dependencies",
  },
  {
    name: "פוסל",
    type: "exclusions",
  },
  {
    name: "מבטל",
    type: "removes",
  },
  {
    name: "סט",
    type: "connetedWith",
  },
];
export default function Relationships({ relations, onChange }) {
  const [partModalOpen, setPartModalOpen] = useState(false);
  const [partModalType, setPartModalType] = useState("");
  const [selectedParts, setSelectedParts] = useState([]);

  const change = (action, value) => {
    if (action === 1) {
      setSelectedParts([...selectedParts, value]);
    } else if (action === -1) {
      setSelectedParts(selectedParts.filter((x) => x !== value));
    }
  };
  const open = (type) => {
    setPartModalOpen(true);
    setPartModalType(type);
    setSelectedParts(relations[type] || []);
  };
  const close = () => {
    onChange({ ...relations, [partModalType]: selectedParts });
    setPartModalOpen(false);
    setPartModalType("");
    setSelectedParts([]);
  };
  return (
    <div className={classes.container}>
      {options.map(({ name, type }) => (
        <div className={classes.column}>
          <h3>{name}</h3>
          <div className={classes.items}>
            {relations[type]?.map((x) => (
              <span>{x}</span>
            ))}
          </div>
          <Button onClick={() => open(type)}>
            <Icon name="plus" />
          </Button>
        </div>
      ))}

      <Modal
        open={partModalOpen}
        style={{ height: "90%", overflow: "auto" }}
        onClose={close}
      >
        <PartBrowser selected={selectedParts} onChange={change} />
      </Modal>
    </div>
  );
}
