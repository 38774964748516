import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { apiGet } from "../../Scripts/Api";
import Stage from "./Stage/Stage";
import classes from "./StageManager.module.scss";
export default function StageManager() {
  const [stages, setStages] = useState([]);
  const { params } = useSelector((state) => state.metaReducer);

  useEffect(() => {
    apiGet("config/stages").then(({ data }) => setStages(data.stages));
  }, []);
  const addStage = () => {
    setStages([...stages, { params: [] }]);
  };
  const removeStage = (i) => {
    const newStages = [...stages];
    newStages.splice(i, 1);
    setStages(newStages);
  };

  const stageChanged = (e, i) => {
    const newStages = [...stages];

    newStages[i].params = e;
    setStages(newStages);
  };
  const update = () => {};
  const getStages = () => {
    const usedParams = stages.reduce((acc, val) => {
      return [...acc, ...val.params.map((x) => x.paramId)];
    }, []);
    const availableParams = params.filter((p) => !usedParams.includes(p.id));
    return stages.map((stage, i) => {
      return (
        <Stage
          key={i}
          index={i}
          avaliabelParams={availableParams}
          onChange={(e) => stageChanged(e, i)}
          items={stage.params}
          remove={() => removeStage(i)}
        />
      );
    });
  };
  return (
    <div className={classes.container}>
      <div className={classes.items}>
        {getStages()}
        <div className={classes.add} onClick={addStage}>
          <Icon name="plus" />
        </div>
      </div>

      <Button onClick={update}>עדכן</Button>
    </div>
  );
}
