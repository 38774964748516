export const INIT = "init";
export const SET_PART_TYPES = "SET_PART_TYPES";
export const SET_PARAMS = "init";
export const SET_PARTS = "SET_PARTS";
export const SET_USER = "SET_USER";
export const LOG_OUT_USER = "LOG_OUT_USER";

export const ADD_PART = "ADD_PART";
export const UPDATE_PART = "UPDATE_PART";
export const ADD_PART_TYPE = "ADD_PART_TYPE";
export const UPDATE_PART_TYPE = "UPDATE_PART_TYPE";
export const UPDATE_PARAM = "UPDATE_PARAM";
export const ADD_PARAM = "ADD_PARAM";
export const ADD_VIEW = "ADD_VIEW";
export const UPDATE_VIEW = "UPDATE_VIEW";
export const ADD_DISPLAY = "ADD_DISPLAY";
export const EDIT_DISPLAY = "EDIT_DISPLAY";
export const DELETE_PART = "DELETE_PART";
