import React from "react";
import { useSelector } from "react-redux";
import ParamTable from "./ParamTable/paramTable";
import classes from "./Prameters.module.scss";
export default function Parameters({ onChange, conditions }) {
  const { params } = useSelector((state) => state.metaReducer);

  const change = (id, obj) => {
    let con = conditions.find((x) => x.paramId === id);
    if (!con) return onChange([...conditions, obj]);

    con.test = obj.test;
    onChange([...conditions]);
  };

  const removeParam = (id) => {
    const newConditions = [...conditions].filter((x) => x.paramId !== id);
    onChange(newConditions);
  };
  const getConditionedParams = () => {
    const filteredParams = params.filter((p) => {
      const { condition } = p;
      if (!condition) return true;

      const { paramId: dependedParam, test } = condition;

      const dependentParamValue = conditions.find(
        (x) => x.paramId === dependedParam
      );

      if (dependentParamValue === undefined) return false;
      const compareTo = dependentParamValue.test.equal;

      return test.equal.includes(compareTo);
    });
    return filteredParams;
  };
  // getConditionedParams();

  return (
    <div className={classes.container}>
      {getConditionedParams().map((x) => (
        <ParamTable
          param={x}
          onChange={(e) => change(x.id, e)}
          onRemove={(id) => removeParam(id)}
          value={
            conditions.find((con) => con.paramId === x.id) || {
              paramId: x.id,
              test: {},
            }
          }
        />
      ))}
    </div>
  );
}
