import React from "react";
import { Route, Switch } from "react-router-dom";
import Panel from "../../Components/UI/Panel/Panel.jsx";
import Navbar from "../../Components/UI/Navbar/Navbar.jsx";
import Classes from "./Manager.module.css";
import Hub from "../Hub/Hub.jsx";
import Parts from "../Parts/Parts.jsx";
import Clients from "../Clients/Clients.jsx";

function Manager(props) {
  return (
    <div className={Classes.main}>
      <Navbar />

      <Panel style={{ background: "white" }}>
        <Switch>
          <Route path="/hub">
            <Hub />
          </Route>
          <Route path="/Parts">
            <Parts />
          </Route>
          <Route path="/clients">
            <Clients />
          </Route>
        </Switch>
      </Panel>
    </div>
  );
}

// class Manager extends Component {
//   state = {
//     notification: {
//       show: false,
//       text: "",
//     },
//     redirect: null,
//   };
//   pages = [
//     {
//       route: "/hub/add",
//       label: "הוסף פריט",
//     },
//     {
//       route: "/Parts",
//       label: "פריטים",
//     },
//     {
//       route: "/Orders",
//       label: "הזמנות",
//     },
//     {
//       route: "/Clients",
//       label: "לקוחות",
//     },
//   ];
//   componentDidUpdate(prevProps, prevState) {
//     if (prevState.redirect !== null) {
//       this.setState({ redirect: null });
//     }
//   }
//   showNotification = (msg, dur) => {
//     return;
//     // this.setState({
//     //   notification: { show: true, text: msg, dur: dur }
//     // });
//     // setTimeout(() => {
//     //   this.setState({ notification: { show: false } });
//     // }, dur);
//   };
//   setRedirect = (path, data) => {
//     this.setState({ redirect: { path: path, data: data } });
//   };
//   render() {
//     if (this.state.redirect !== null) {
//       return (
//         <Redirect
//           to={{
//             pathname: this.state.redirect.path,
//             state: this.state.redirect.data,
//           }}
//         />
//       );
//     }
//     return (
//       <div className={Classes.main}>
//         <NotificationContainer />
//         {auth.isAuth() ? <Navbar items={this.pages} /> : null}

//         <Switch>
//           <Route path="/" exact component={Login} />
//           <Panel style={{ background: "white" }}>
//             {
//               //Protected Routes}
//               auth.isAuth() ? (
//                 <>
//                   <Route
//                     path="/hub"
//                     render={(routeProps) => <Hub {...routeProps} />}
//                   />
//                   <Route
//                     path="/Parts"
//                     render={(routeProps) => (
//                       <Parts
//                         {...routeProps}
//                         showNotification={this.showNotification}
//                       />
//                     )}
//                   />
//                   <Route
//                     path="/Orders"
//                     render={(props) => (
//                       <Orders
//                         {...props}
//                         data={
//                           this.state.redirect ? this.state.redirect.data : ""
//                         }
//                       />
//                     )}
//                   />
//                   <Route
//                     path="/Clients"
//                     render={(props) => (
//                       <Customers {...props} setRedirect={this.setRedirect} />
//                     )}
//                   />
//                 </>
//               ) : null
//             }
//           </Panel>
//         </Switch>
//       </div>
//     );
//   }
// }

export default Manager;
