import { LOG_OUT_USER, SET_USER } from "../Actions/actionsTypes";

const initState = {
  user: { loggedIn: false },
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: { ...action.payload, loggedIn: true } };
    case LOG_OUT_USER:
      return { ...initState };
    default:
      return state;
  }
};
export default reducer;
