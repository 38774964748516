import { apiGet } from "../../Scripts/Api";
import * as actions from "./actionsTypes";

export const init = () => async (dispatch) => {
  const { data: partTypes } = await apiGet("parts/types");
  const { data: params } = await apiGet("parts/params");
  const { data: views } = await apiGet("config/views");
  const { data: displays } = await apiGet("parts/displays");

  dispatch({
    type: actions.INIT,
    payload: { partTypes, params, views, displays },
  });
};
export const addType = (type) => {
  return { type: actions.ADD_PART_TYPE, payload: type };
};
export const updateType = (type) => {
  return { type: actions.UPDATE_PART_TYPE, payload: type };
};
export const addParam = (param) => {
  return { type: actions.ADD_PARAM, payload: param };
};
export const updateParam = (param) => {
  return { type: actions.UPDATE_PARAM, payload: param };
};
export const addView = (view) => {
  return { type: actions.ADD_VIEW, payload: view };
};
export const updateView = (view) => {
  return { type: actions.UPDATE_VIEW, payload: view };
};

export const addDisplay = (display) => {
  return { type: actions.ADD_DISPLAY, payload: display };
};
export const editDisplay = (display) => {
  return { type: actions.EDIT_DISPLAY, payload: display };
};
