import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { authClient } from "../../API/auth";
import { apiGet } from "../../Scripts/Api";

export default function Clients() {
  const [clients, setClients] = useState([]);
  useEffect(() => {
    apiGet("auth/clients").then(({ data }) => {
      setClients(data);
    });
  }, []);
  const auth = (id, status) => {
    authClient(id, status).then(() => {
      apiGet("auth/clients").then(({ data }) => {
        setClients(data);
      });
    });
  };
  const getStatus = (id, status) => {
    if (status === "Authorized") {
      return <Icon name="check circle" color="green" size="large" />;
    }
    if (status === "Rejected") {
      return <Icon name="minus circle" color="red" size="large" />;
    }
    if (status === "Pending") {
      return (
        <>
          <Button
            icon="minus circle"
            compact
            color="red"
            size="tiny"
            onClick={() => auth(id, 0)}
          />
          <Button
            icon="check circle"
            compact
            color="green"
            size="tiny"
            onClick={() => auth(id, 1)}
          />
        </>
      );
    }
  };
  return (
    <div>
      <Table collapsing>
        <Table.Header>
          <Table.HeaderCell>שם</Table.HeaderCell>
          <Table.HeaderCell>מייל</Table.HeaderCell>
          <Table.HeaderCell>טלפון</Table.HeaderCell>
          <Table.HeaderCell>סטטוס</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {clients.map((x) => (
            <Table.Row>
              <Table.Cell>{x.name}</Table.Cell>
              <Table.Cell>{x.email}</Table.Cell>
              <Table.Cell>{x.phoneNumber}</Table.Cell>
              <Table.Cell>{getStatus(x._id, x.authStatus)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
