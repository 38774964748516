import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown, Input, Label } from "semantic-ui-react";

export default function ContainerForm({
  initValue = {},
  action = {},
  loading,
  changed,
}) {
  const [name, setName] = useState(initValue.name || "");
  const [exclusive, setExclusive] = useState(initValue.exclusiveTypes || []);
  const [excludes, setExludes] = useState(initValue.excludes || []);
  const { partTypes } = useSelector((state) => state.metaReducer);
  const submit = () => {
    const obj = {
      name,
      excludes,
      exclusiveTypes: exclusive,
    };
    changed(obj);
  };
  return (
    <div>
      <Input
        label="שם"
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <br />
      <br />
      <Label>איסור</Label>
      <Dropdown
        multiple
        options={partTypes.map((x) => ({ text: x.name, value: x.id }))}
        selection
        text={!excludes.length && "בחר סוגים"}
        value={excludes || ""}
        onChange={(e, { value }) => {
          setExludes(value);
        }}
      />
      <br />
      <Label>אקסקלוסיביות</Label>
      <Dropdown
        options={partTypes.map((x, i) => ({ text: x.name, value: x.id }))}
        button
        multiple
        text={!exclusive.length && "בחר סוגים"}
        value={exclusive || ""}
        onChange={(e, { value }) => {
          setExclusive(value);
        }}
      />
      <br />
      <Button loading={loading} onClick={submit}>
        {action.text}
      </Button>
    </div>
  );
}

{
  /* <Label>אקסלוסיביות</Label>
<Dropdown
  multiple
  options={partTypes.map((x) => ({ text: x.name, value: x.id }))}
  selection
  text={!exclusive.length && "בחר סוגים"}
  value={exclusive || ""}
  onChange={(e, { value }) => {
    setExclusive(value);
  }}
/> */
}
