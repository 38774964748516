import jwtDecode from "jwt-decode";
import { apiGet } from "../../Scripts/Api";
import * as actions from "./actionsTypes";

export const setUser = () => {
  const token = localStorage.getItem("auth");
  if (!token) return { type: "" };
  const jwt = jwtDecode(token);
  delete jwt.exp;
  delete jwt.iat;
  return { type: actions.SET_USER, payload: jwt };
};

export const logOutUser = () => {
  localStorage.removeItem("auth");

  return { type: actions.LOG_OUT_USER, payload: {} };
};
export const loginUser = (token) => {
  localStorage.setItem("auth", token);
  return setUser();
};
