import React, { useEffect, useState } from "react";
import { Button, Dropdown, Icon, Input, Table } from "semantic-ui-react";
import classes from "./ParamTable.module.scss";

export default function ParamTable({ param, onChange, onRemove, value }) {
  const [selectedType, setSelectedType] = useState("=");
  useEffect(() => {
    //if there is no test, = is the default
    if (Object.keys(value.test).length === 0) setSelectedType("=");
    //if a range object exsists then its a range operator
    else if (value.test.range) setSelectedType("↔");
    //This is a fuck up on my part, if comparing  only to one item it should still be in array e.g-> ["test"], i fucked up and inserted simply "test", so this is the fuck up fix - only on local db
    else if (!Array.isArray(value.test.equal)) setSelectedType("=");
    else if (value.test.equal.length === 1) setSelectedType("=");
    else {
      setSelectedType("⊆");
    }
  }, [value]);
  const getOperators = () => {
    const operators = ["="];

    if (param.type.length === 1) {
      const type = param.type[0];
      if (type === "number") operators.push("↔");
    } else operators.push("⊆");
    return operators;
  };

  const typeChanged = (val) => {
    if (val === "=" || val === "⊆") onChange({ ...value, test: { equal: 0 } });
    else if (val === "↔")
      onChange({ ...value, test: { range: { min: 0, max: 10 } } });
    setSelectedType(val);
  };

  const valueSet = (type, val) => {
    const base = { name: param.name, paramId: param.id, test: {} };

    switch (type) {
      case "remove":
        setSelectedType("=");

        onRemove(val.id);
        break;
      case "max":
        onChange({
          ...base,
          test: { range: { ...value.test.range, max: val } },
        });
        break;

      case "min":
        onChange({
          ...base,
          test: { range: { ...value.test.range, min: val } },
        });
        break;
      case "equal":
        if (!Array.isArray(val)) val = [val];
        onChange({ ...base, test: { equal: val } });
        break;
      default:
        break;
    }
  };
  const getParams = () => {
    const headers = [];
    const content = [];

    if (selectedType === "↔") {
      headers.push("מינימום");
      headers.push("מקסימום");
      content.push(
        <Input
          type="number"
          onChange={(e) => valueSet("min", e.target.value)}
          value={value.test.range.min || ""}
        />
      );
      content.push(
        <Input
          type="number"
          onChange={(e) => valueSet("max", e.target.value)}
          value={value.test.range.max || ""}
        />
      );
    } else if (selectedType === "=" && param.type.length === 1) {
      headers.push("ערך");
      content.push(
        <Input
          onChange={(e) => valueSet("equal", e.target.value)}
          value={value.test.equal || ""}
        />
      );
    } else {
      headers.push("רשימה");

      let dropDownValue;

      if (Object.keys(value.test).length === 0) {
        dropDownValue = "";
      } else if (selectedType === "=")
        //This is a fuck up on my part, if comparing  only to one item it should still be in array e.g-> ["test"], i fucked up and inserted simply "test", so this is the fuck up fix - only on local db
        //Should be .equal[0] always
        dropDownValue = Array.isArray(value.test.equal)
          ? value.test.equal[0]
          : value.test.equal;
      else dropDownValue = value.test.equal;

      content.push(
        <Dropdown
          options={param.type.map((x) => ({ text: x, value: x }))}
          multiple={selectedType !== "="}
          onChange={(e, data) => valueSet("equal", data.value)}
          button
          value={dropDownValue}
          scrolling
        />
      );
    }
    return [headers, content];
  };

  const [headers, content] = getParams();

  return (
    <div className={classes.table}>
      <table>
        <thead>
          <tr>
            <th />
            <th />
            {headers.map((x) => (
              <th>{x}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {param.name}
              {Object.keys(value.test).length ? (
                <Button
                  icon
                  style={{ background: "transparent" }}
                  onClick={() => valueSet("remove", param)}
                >
                  <Icon name="trash" color="red" />
                </Button>
              ) : (
                ""
              )}
            </td>
            <td>
              <Dropdown
                options={getOperators().map((x) => ({ text: x, value: x }))}
                button
                value={selectedType}
                onChange={(e, data) => typeChanged(data.value)}
                icon={null}
              />
            </td>
            {content.map((x) => (
              <td>{x}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
