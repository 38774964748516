import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import api from "../../API/api";
import TypeForm from "../TypeForm/TypeForm";
import classes from "./TypeManager.module.scss";
import { addType, updateType } from "../../Redux/Actions/metaActions";
import { NotificationManager } from "react-notifications";
import ListSelector from "../listSelector/ListSelector";
export default function TypeManager() {
  const [selectedType, setSelectedType] = useState({});
  const [showAddTypeForm, setShowAddTypeForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { partTypes } = useSelector((state) => state.metaReducer);

  const update = (e) => {
    console.log(e);
    setIsLoading(true);
    api
      .upateType(e, selectedType.id)
      .then(({ data }) => {
        console.log(data);
        dispatch(updateType(data));

        if (data)
          NotificationManager.success("הסוג עודכן בהצלחה", "עדכון סוג", 2000);
        else NotificationManager.error("נכשל לעדכן סוג", "עדכון סוג", 2000);
      })
      .finally(() => setIsLoading(false));
  };
  const add = (e) => {
    setIsLoading(true);
    api
      .addType(e)
      .then(({ data, err }) => {
        if (data) {
          setShowAddTypeForm(false);
          dispatch(addType(data));
          NotificationManager.success("הסוג נוסף הבצלחה", "הוספת סוג", 2000);
        } else NotificationManager.error("נכשל להוסיף סוג", "עדכון סוג", 2000);
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <div className={classes.container}>
      <Modal open={showAddTypeForm} onClose={() => setShowAddTypeForm(false)}>
        <Modal.Header>הוסף סוג</Modal.Header>
        <Modal.Content>
          <TypeForm
            action={{ text: "הוסף", color: "green", onClick: add }}
            loading={isLoading}
          />
        </Modal.Content>
      </Modal>
      <aside>
        {/* <TypeSelector
          onChange={setSelectedType}
          value={selectedType}
          addType={() => setShowAddTypeForm(true)}
        /> */}
        <ListSelector
          items={partTypes}
          onChange={setSelectedType}
          selected={selectedType}
          addItem={() => setShowAddTypeForm(true)}
        />
      </aside>
      <main>
        <TypeForm
          initValue={selectedType}
          key={selectedType.id}
          edit
          loading={isLoading}
          action={{ text: "עדכן", color: "green", onClick: update }}
        />
      </main>
    </div>
  );
}
