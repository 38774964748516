import React from "react";
import { Button, Radio } from "semantic-ui-react";
import classes from "./ParamItem.module.scss";
export default function ParamItem({ param, item, i, removeItem, onChange }) {
  //fucking reversed cause rtl, order is FINE.
  const label = param.type.length === 1 ? "טקסט" : "רשימה";

  const isChecked =
    item.display.type === "Default" || item.display.type === "List";

  return (
    <div className={classes.item}>
      <div className={classes.head}>
        <Button onClick={() => removeItem(item)} icon="trash" size="tiny" />
        <p>{param.name}</p>
      </div>
      <span className={classes.controlls}>
        <span>
          <input
            type="radio"
            name={`rad_${i}`}
            onChange={(e) => onChange({ display: { type: "List" } })}
            checked={isChecked}
          />
          <label>{label}</label>
        </span>
        {param.type.length > 1 && (
          <span>
            <input
              type="radio"
              name={`rad_${i}`}
              onChange={(e) => onChange({ display: { type: "Buttons" } })}
              checked={item.display.type === "Buttons"}
            />
            <label>כפתורים</label>
          </span>
        )}
      </span>
    </div>
  );
}
