import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Form, Message } from "semantic-ui-react";
import { loginUser, setUser } from "../../Redux/Actions/userActions";
import { apiSet } from "../../Scripts/Api";
import classes from "./Login.module.scss";
export default function Login() {
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [err, setErr] = useState(false);
  const dispatch = useDispatch();
  const submit = () => {
    apiSet("auth/login/admin", { email, password: pwd }).then(
      ({ error, data }) => {
        if (error) {
          setErr(true);
        } else {
          dispatch(loginUser(data));
        }
      }
    );
  };
  return (
    <div className={classes.form}>
      <Form onSubmit={submit}>
        <Form.Input
          required
          label="שם משתמש"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Form.Input
          required
          label="סיסמא"
          type="password"
          value={pwd}
          onChange={(e) => setPwd(e.target.value)}
        />
        {err && <Message negative>שם משתמש או סיסמא שגויים</Message>}
        <Button type="submit">התחבר</Button>
      </Form>
    </div>
  );
}
