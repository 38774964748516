export const authClient = async (clientId, status) => {
  try {
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/${clientId}`,
      {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
        body: JSON.stringify({ status: status }),
      }
    ).then((rawData) => rawData.json());

    return data;
  } catch {
    return Promise.reject();
  }
};
