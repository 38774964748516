import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import classes from "./PartCard.module.scss";
export default function PartCard({ part = {}, openItemEdit }) {
  return (
    <div className={classes.card} onClick={() => openItemEdit(part)}>
      {part.image ? (
        <img src={part.image} alt={part.catNumber} />
      ) : (
        <span className={classes.imageIcon}>
          <Icon name="image" size="massive" />
        </span>
      )}
      <a href={part.link || ""} target="_blank">
        {part.catNumber}
      </a>
    </div>
  );
}
