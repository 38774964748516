import React, { useEffect, useState } from "react";
import { Button, Checkbox, Label, Modal } from "semantic-ui-react";
import DisplayBrowser from "../../DisplayBrowser/DisplayBrowser";
import DisplayImageManager from "../../DisplayImageManager/DisplayImageManager";

import PositionEditor from "../../PositionEditor/PositionEditor";
import classes from "./DisplayEditor.module.scss";

const defaultItem = {
  width: 0.2,
  height: 0.2,
  left: 0,
  top: 0,
};
export default function DisplayEditor({
  type,
  value,
  onChange = () => {},
  mode,
}) {
  const [items, setItems] = useState(value?.items || []);
  const [showManager, setshowManager] = useState(false);
  const [previewImage, setPreviewImage] = useState(value?.previewImage || "");

  // useEffect(() => {
  //   setItems(value.items || []);
  //   console.log(value);
  //   setPreviewImage(value.previewImage || "");
  //   setActive(value?.preview?.visible || false);
  // }, [value]);

  useEffect(() => {
    onChange({ ...value, previewImage, items });
    // else onChange({ preview: { visible: false } });
  }, [items, previewImage]);

  const addItem = () => {
    setItems((oldItems) => [...oldItems, { ...defaultItem }]);
  };
  const removeItem = () => {
    setItems((oldItems) => {
      oldItems.pop();
      return [...oldItems];
    });
  };

  const change = (obj, i) => {
    setItems((oldItems) => {
      const newItems = [...oldItems];
      newItems.splice(i, 1);
      newItems.push(obj);
      return newItems;
    });
  };
  const previewSelected = (url) => {
    setPreviewImage(url);
    setshowManager(false);
  };
  return (
    <div className={classes.container}>
      <Modal
        open={showManager}
        onClose={() => setshowManager(false)}
        style={{ textAlign: "right" }}
        centered={false}
      >
        <Modal.Header>
          <h1>ניהול תמונות תצוגה</h1>
        </Modal.Header>
        <Modal.Content>
          <DisplayImageManager id={type?.id} selected={previewSelected} />
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
      {/* 
      <Checkbox
        toggle
        checked={active}
        onChange={(e, data) => setActive(data.checked)}
      /> */}
      <br />
      <div className={!true && classes.disabled}>
        <PositionEditor
          onChange={change}
          items={items}
          itemImage={previewImage}
          disabled={mode === "View"}
        />
        <div
          className={
            classes.actions + " " + (mode === "View" && classes.disabled)
          }
        >
          <Button icon="minus" onClick={removeItem} color="red" />
          <Button
            color="blue"
            onClick={() => setshowManager(true)}
            icon="image"
            disabled={items.length === 0}
          />
          <Button icon="add" onClick={addItem} color="green" />
        </div>
      </div>
    </div>
  );
}
