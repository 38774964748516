import React, { useRef, useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";
import classes from "./UploadImage.module.scss";
function UploadImage({
  icon,
  onChange = () => {},
  getDataURL = () => {},
  value,
}) {
  const [previewImage, setPreviewImage] = useState("");

  const inputRef = useRef();
  useEffect(() => {
    setPreviewImage(value || "");
  }, [value]);
  const imageSelected = (ev) => {
    ev.persist();
    if (ev.target.files && ev.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target.result);
        getDataURL(e.target.result);
      };
      reader.readAsDataURL(ev.target.files[0]);
      onChange(ev.target.files[0]);
    }
  };
  const removeImage = () => {
    setPreviewImage("");
    onChange("");
  };
  return (
    <div className={classes.container}>
      <div className={classes.image}>
        {previewImage.length ? (
          <img src={previewImage} alt="preview" />
        ) : (
          <div className={classes.icon}>
            <Icon
              name={icon || "image"}
              style={{
                transform: "translateY(25%)",
              }}
            />
          </div>
        )}
      </div>
      <div className={classes.controls}>
        <input ref={inputRef} type="file" hidden onChange={imageSelected} />
        <Button
          onClick={() => inputRef.current.click()}
          icon="upload"
          size="big"
          fluid
          color="green"
        />
        <Button
          onClick={removeImage}
          icon="close"
          size="big"
          fluid
          color="red"
        />
      </div>
    </div>
  );
}

export default UploadImage;
