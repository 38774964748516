import React, { useState } from "react";
import { Button } from "semantic-ui-react";

export default function Delete({ confirm = () => {} }) {
  const [validateDelete, setValidateDelete] = useState(false);
  return (
    <div>
      {validateDelete ? (
        <>
          <Button
            icon="x"
            color="red"
            onClick={() => setValidateDelete(false)}
          />
          <Button
            icon="check"
            color="green"
            onClick={() => {
              confirm();
              setValidateDelete(false);
            }}
          />
        </>
      ) : (
        <Button
          icon="trash"
          color="red"
          onClick={() => setValidateDelete(true)}
        />
      )}
    </div>
  );
}
