import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown, Input, Modal, Table } from "semantic-ui-react";
import Resizer from "../Resizer/Resizer";
import ContainerForm from "./ContainerEditor/ContainerForm";

import classes from "./ViewEditor.module.scss";
import * as api from "../../API/viewCRUD";
import { NotificationManager } from "react-notifications";
/*
  Views should have held the container name, and when rendering the containers, filter the views accordingly,
  Insted The Containers hold the views... Which just complecates things...Soooooo.... yeah

*/
export default function ViewEditor({
  viewImage,
  initContrainers,
  onChange = () => {},
  id,
}) {
  const { views: AllViews, partTypes } = useSelector(
    (state) => state.metaReducer
  );

  const [viewContainers, setViewContianers] = useState(
    initContrainers || [{ views: [], name: "C1" }]
  );

  const [showAddContainerModel, setShowContainerModal] = useState(false);

  const [containerForEdit, setContainerForEdit] = useState(undefined);

  const viewContainerRefs = useRef([]);

  const updateView = (containerIndex, viewIndex, prop, value) => {
    const newContainers = [...viewContainers];
    const newViews = [...newContainers[containerIndex].views];
    newViews[viewIndex][prop] = value;
    newContainers[containerIndex].views = newViews;
    setViewContianers(newContainers);
  };
  const removeView = (containerIndex, viewIndex) => {
    const newContainers = [...viewContainers];
    const newViews = [...newContainers[containerIndex].views];
    newViews.splice(viewIndex, 1);
    newContainers[containerIndex].views = newViews;
    setViewContianers(newContainers);
  };

  const addViewRef = (ref) => {
    if (ref && !viewContainerRefs.current.includes(ref))
      viewContainerRefs.current.push(ref);
  };
  useEffect(() => {
    onChange(viewContainers);
  }, [viewContainers]);

  useEffect(() => {
    //just added, add the view
    if (viewContainers.length === 1) addView();
  }, [viewContainers.length]);

  const getAllViews = () => {
    return viewContainers.reduce((acc, val, i) => {
      //turn val to an array of views, with the container index, and inner view index in it so it won't be lost when combining to single array
      const views = val.views.map((view, viewIndex) => ({
        containerIndex: i,
        viewIndex,
        view,
      }));

      return [...acc, ...views];
    }, []);
  };
  const addView = () => {
    if (viewContainers.length === 0) {
      return setShowContainerModal(true);
    }

    const newContainers = [...viewContainers];
    let newViews = [...newContainers[0].views];
    newViews = [
      ...newViews,
      {
        positionObject: {},
        name: "R",
        color: "#" + Math.floor(Math.random() * 16777215).toString(16),
        transform: ".",
      },
    ];
    newContainers[0].views = newViews;
    setViewContianers(newContainers);
  };
  const addContainer = () => {
    setViewContianers([
      ...viewContainers,
      // { views: [], name: newContainerName },
      { views: [] },
    ]);
    closeContainerModel();
  };
  const closeContainerModel = () => {
    // setNewContainerName("");
    setShowContainerModal(false);
  };

  const transferView = (targetContainerIndex, srcContainerIndex, viewIndex) => {
    if (targetContainerIndex === srcContainerIndex) return;

    const newContainers = [...viewContainers];
    let srcContainerViews = [...newContainers[srcContainerIndex].views];
    let targetContainerViews = [...newContainers[targetContainerIndex].views];

    const daView2Tranfer = srcContainerViews.splice(viewIndex, 1)[0];
    targetContainerViews = [...targetContainerViews, daView2Tranfer];

    newContainers[srcContainerIndex].views = srcContainerViews;
    newContainers[targetContainerIndex].views = targetContainerViews;

    setViewContianers(newContainers);
  };

  const addOrEditView = (containerIndex) => {
    if (containerIndex === undefined) {
      setContainerForEdit(undefined);
      setShowContainerModal(true);
    } else {
      setContainerForEdit(containerIndex);
      setShowContainerModal(true);
    }
  };

  //Each view has containers, the containers hold views, it is not the same view, fucking stupid
  const containerChanged = (viewtoChange) => {
    const view = AllViews.find((x) => x.id === id);
    if (!view) return;
    const newView = { ...view };
    newView.containers[containerForEdit].name = viewtoChange.name;
    newView.containers[containerForEdit].excludes = viewtoChange.excludes;
    newView.containers[containerForEdit].exclusiveTypes =
      viewtoChange.exclusiveTypes;

    api.updateView(id, view).then(() => {
      onChange(newView);
      NotificationManager.success("updated", "View Editor");
    });
  };
  return (
    <div className={classes.container}>
      <Modal open={showAddContainerModel} onClose={closeContainerModel}>
        <Modal.Header>הוסף מיכל</Modal.Header>
        <Modal.Content>
          <ContainerForm
            action={{
              text: containerForEdit !== undefined ? "ערוך" : "הוסף",
              onClick: addContainer,
            }}
            initValue={viewContainers[containerForEdit]}
            changed={containerChanged}
          />
        </Modal.Content>
      </Modal>
      <div>
        {viewContainers.map((container, i) => (
          <div className={classes.view} ref={addViewRef} key={i}>
            <img
              src={viewImage}
              alt=""
              className={classes.bg}
              draggable={false}
            />
            {container.views.map((view, j) => (
              <div>
                <Resizer
                  // key={`${view.name}_${j}`}
                  ref={{ current: viewContainerRefs.current[i] }}
                  value={view.positionObject}
                  onChange={(e) => {
                    updateView(i, j, "positionObject", e);
                  }}
                  dumbStyle={{
                    borderColor: view.color,
                    borderStyle: "Solid",
                    borderWidth: "5px",
                  }}
                >
                  <span className={classes.viewTitlePreview}>{view.name}</span>
                </Resizer>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={classes.controlBar}>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>שם אזור</Table.HeaderCell>
              <Table.HeaderCell>צבע</Table.HeaderCell>
              <Table.HeaderCell>אקסקלוסיביות</Table.HeaderCell>
              <Table.HeaderCell>טרנספורמציה</Table.HeaderCell>
              <Table.HeaderCell>מיכל</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {getAllViews().map(({ view, containerIndex, viewIndex }, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <Input
                    value={view.name}
                    onChange={(e) =>
                      updateView(
                        containerIndex,
                        viewIndex,
                        "name",
                        e.target.value
                      )
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <input
                    type="color"
                    value={view.color}
                    onChange={(e) =>
                      updateView(
                        containerIndex,
                        viewIndex,
                        "color",
                        e.target.value
                      )
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                    options={partTypes.map((x, i) => ({
                      text: x.name,
                      value: x.id,
                    }))}
                    button
                    multiple
                    text={!view.exclusiveTypes?.length && "בחר סוגים"}
                    value={view.exclusiveTypes || []}
                    onChange={(e, { value }) => {
                      updateView(
                        containerIndex,
                        viewIndex,
                        "exclusiveTypes",
                        value
                      );
                    }}
                  />
                </Table.Cell>

                <Table.Cell>
                  <Dropdown
                    options={[
                      { text: "מקורי", value: "." },
                      { text: "הפוך ב-X", value: "-X" },
                      { text: "הפוך ב-Y", value: "-Y" },
                    ]}
                    selection
                    placeholder="בחר פריפאב"
                    onChange={(e, { value }) =>
                      updateView(containerIndex, viewIndex, "transform", value)
                    }
                    value={view.transform}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                    selection
                    text={viewContainers[containerIndex].name}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Header icon="box" content="מכולות" />
                      {viewContainers.map((x, newContainerIndex) => (
                        <Dropdown.Item
                          text={x.name}
                          value={x.name}
                          onClick={() =>
                            transferView(
                              newContainerIndex,
                              containerIndex,
                              viewIndex
                            )
                          }
                        />
                      ))}
                      <Dropdown.Header icon="cog" content="פעולות" />
                      <Dropdown.Divider />
                      <Dropdown.Item
                        icon="add"
                        text="הוסף"
                        onClick={() => addOrEditView()}
                        disabled
                      />
                      <Dropdown.Item
                        icon="edit"
                        text="ערוך"
                        onClick={() => addOrEditView(containerIndex)}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
                <Table.Cell>
                  <Button icon="trash" onClick={() => removeView(i)} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Button icon="add" onClick={addView} />
      </div>
    </div>
  );
}
