import React, { useState } from "react";
import ViewProfileForm from "../ViewProfileForm/ViewProfileForm";
import api from "../../API/api";

import { useDispatch, useSelector } from "react-redux";

import {
  updateView as updateViewAction,
  addView as addViewAction,
} from "../../Redux/Actions/metaActions";
import ListSelector from "../listSelector/ListSelector";
import classes from "./ViewManager.module.scss";
export default function ViewManager() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedView, setSelectedView] = useState(undefined);

  const { views } = useSelector((state) => state.metaReducer);
  const dispatch = useDispatch();
  const addProfile = (e) => {
    setIsLoading(true);
    api.addView(e).finally(() => setIsLoading(false));
  };
  const updateProfile = (e) => {
    // console.log(e);
    // return;
    setIsLoading(true);
    api
      .updateView(selectedView.id, e)
      .then(({ view }) => {
        dispatch(updateViewAction(view));
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <div className={classes.container}>
      <aside>
        <ListSelector
          items={views}
          onChange={setSelectedView}
          selected={selectedView}
          compareParam="name"
        />
      </aside>
      <main>
        {selectedView && (
          <ViewProfileForm
            action={{ onClick: updateProfile, color: "red", text: "עדכן" }}
            loading={isLoading}
            initValue={selectedView}
            key={selectedView.name}
          />
        )}
      </main>
    </div>
  );
}
