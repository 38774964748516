import store from "../Redux/store";
import { uploadImage } from "../Scripts/upload";

const processPartObj = (part) => {
  const {
    partReducer: { parts },
  } = store.getState();

  part.type = part.type.id;

  // // //cast relations from string to their id's
  // // const getId = (cat) => parts.find((x) => x.catNumber === cat).id;
  // // const relationships = {};
  // // Object.entries(part.relations).forEach(
  // //   ([key, value]) => (relationships[key] = value.map(getId))
  // // );
  // part.relationships = relationships;
  part.relationships = part.relations;
  delete part.relations;
};

export const addPart = async (part) => {
  const image = part.image;
  processPartObj(part);

  if (part.image) {
    const { data } = await uploadImage(image);
    part.image = data;
  } else part.image = "";

  const data = await fetch(`${process.env.REACT_APP_API_URL}/parts/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth")}`,
    },
    body: JSON.stringify(part),
  }).then((rawData) => rawData.json());

  return data;
};

export const updatePart = async (id, part) => {
  processPartObj(part);
  if (part.image instanceof File) {
    const { data } = await uploadImage(part.image);
    part.image = data;
  }

  const data = await fetch(
    `${process.env.REACT_APP_API_URL}/parts/update/${id}`,
    {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify(part),
    }
  )
    .then((rawData) => rawData.json())
    .catch((x) => console.log("לא מצליח להתחבר לשרת"));

  return data;
};

export const deletePart = async (id) => {
  const data = await fetch(
    `${process.env.REACT_APP_API_URL}/parts/delete/${id}`,
    {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    }
  )
    .then((rawData) => rawData.json())
    .catch((x) => console.log("לא מצליח להתחבר לשרת"));
  return data;
};
