import decode from "jwt-decode";

const isAuth = () => {
  try {
    const token = localStorage.getItem("auth");
    const exp = decode(token).exp;
    if (Date.now() >= exp * 1000) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};
// const getUserData = () => {
//   if (isAuth()) {
//     const token = localStorage.getItem("auth");
//     return decode(token);
//   } else return false;
// };
export default { isAuth };
