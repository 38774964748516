import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import EditPart from "../../Components/EditPart/EditPart";
import PartCard from "../../Components/PartCard/PartCard";
import PartForm from "../../Components/PartForm/PartForm";
import ItemFilter from "../../Components/ItemFilter/ItemFilter";
import classes from "./Parts.module.scss";
export default function Parts() {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editPart, setEditPart] = useState({});
  const [filter, setFilter] = useState({ type: [], cat: "" });
  const { parts } = useSelector((state) => state.partReducer);

  const openItemEdit = (item) => {
    setEditModalOpen(true);
    setEditPart(item);
  };
  const closeItemEdit = () => {
    setEditModalOpen(false);
    setEditPart({});
  };
  return (
    <div>
      <Modal
        open={editModalOpen}
        style={{
          background: "white",
          width: "90%",
          // "width": "100%",
          maxHeight: "90%",
          overflowY: "auto",
        }}
        centered={false}
        onClose={() => setEditModalOpen(false)}
      >
        <Modal.Content>
          <EditPart part={editPart} done={closeItemEdit} />
        </Modal.Content>
      </Modal>
      <div className={classes.filter}>
        <ItemFilter filter={filter} onChange={setFilter} />
      </div>
      <div className={classes.cards}>
        {parts
          .filter((x) =>
            x.catNumber.match(filter.cat) && filter.type.length === 0
              ? true
              : filter.type.includes(x.type)
          )
          .map((x, i) => (
            <PartCard key={i} part={x} openItemEdit={openItemEdit} />
          ))}
      </div>
    </div>
  );
}
