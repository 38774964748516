import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown, Radio } from "semantic-ui-react";
import ParamItem from "./ParamItem/ParamItem";
import classes from "./Stage.module.scss";
export default function Stage({
  items,
  onChange,
  avaliabelParams,
  index,
  remove,
}) {
  // const [items, setItems] = useState([]);
  const { params } = useSelector((state) => state.metaReducer);
  const addItem = (pId) => {
    onChange([...items, { paramId: pId, display: { type: "Default" } }]);
  };

  const removeItem = (item) => {
    onChange(items.filter((x) => x.paramId !== item.paramId));
  };
  const itemChanged = (e, i) => {
    const newItems = [...items];
    newItems[i] = { ...newItems[i], ...e };
    onChange(newItems);
  };
  return (
    <div className={classes.stage}>
      <div className={classes.header}>
        <h3>שלב #{index + 1}</h3>
        <Button icon="trash" size="tiny" onClick={remove} />
      </div>
      <div className={classes.body}>
        <div className={classes.items}>
          {items.map((x, i) => (
            <ParamItem
              item={x}
              key={i}
              i={`${index}_${i}`}
              removeItem={removeItem}
              onChange={(e) => itemChanged(e, i)}
              param={params.find((p) => p.id === x.paramId)}
            />
          ))}
        </div>
        <Dropdown
          options={avaliabelParams.map((x) => ({
            text: x?.name,
            value: x?.id,
          }))}
          style={{ zIndex: 100000 }}
          text="בחר פרמטר"
          selection
          value=""
          onChange={(e, { value }) => addItem(value)}
        />
      </div>
    </div>
  );
}
