import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import classes from "./Collapsable.module.scss";
export default function Collapsable({
  defaultOpen,
  title,
  disabled,
  children,
}) {
  const [open, setOpen] = useState(defaultOpen);
  return (
    <div className={classes.section}>
      <h3
        className={classes.sectionTitle}
        onClick={() => {
          if (!disabled) setOpen(!open);
        }}
      >
        {!disabled && (
          <Icon name={open ? "minus" : "plus"} style={{ cursor: "pointer" }} />
        )}
        {title}
      </h3>
      {open && <div className={classes.content}>{children}</div>}
    </div>
  );
}
