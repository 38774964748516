import { uploadImage } from "../Scripts/upload";
export const addView = async (view) => {
  if (view.image && view.image instanceof File) {
    const { data } = await uploadImage(view.image);
    view.image = data;
  }
  const data = await fetch(
    `${process.env.REACT_APP_API_URL}/config/views/add`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify(view),
    }
  )
    .then((rawData) => rawData.json())
    .catch((x) => console.log("לא מצליח להתחבר לשרת"));
  return data;
};
export const updateView = async (id, view) => {
  if (view.image && view.image instanceof File) {
    const { data } = await uploadImage(view.image);
    view.image = data;
  }
  const data = await fetch(
    `${process.env.REACT_APP_API_URL}/config/views/update/${id}`,
    {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify(view),
    }
  )
    .then((rawData) => rawData.json())
    .catch((x) => console.log("לא מצליח להתחבר לשרת"));
  return data;
};
