import * as actions from "../Actions/actionsTypes";
const initState = {
  partTypes: [],
  params: [],
  views: [],
  displays: [],
};
const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.INIT:
      return { ...state, ...payload };
    case actions.SET_PART_TYPES:
      return { ...state, partTypes: payload };
    case actions.SET_PARAMS:
      return { ...state, params: payload };
    case actions.ADD_PART_TYPE:
      return { ...state, partTypes: [...state.partTypes, payload] };
    case actions.UPDATE_PART_TYPE: {
      const newTypes = [...state.partTypes];
      const index = newTypes.findIndex((x) => x.id === payload.id);
      newTypes[index] = payload;
      return { ...state, partTypes: newTypes };
    }
    case actions.ADD_PARAM:
      return { ...state, params: [...state.params, payload] };

    case actions.UPDATE_PARAM: {
      const newParams = [...state.params];
      const index = newParams.findIndex((x) => x.id === payload.id);
      newParams[index] = payload;
      return { ...state, params: newParams };
    }

    case actions.ADD_VIEW:
      return { ...state, views: [...state.views, payload] };

    case actions.UPDATE_VIEW: {
      const newViews = [...state.views];
      const index = newViews.findIndex((x) => x.id === payload.id);
      newViews[index] = payload;
      return { ...state, views: newViews };
    }
    case actions.ADD_DISPLAY:
      return { ...state, displays: [...state.displays, payload] };
    case actions.EDIT_DISPLAY:
      const newDisplays = [...state.displays];
      const index = newDisplays.findIndex((x) => x.id === payload.id);
      newDisplays[index] = payload;
      return { ...state, displays: newDisplays };

    default:
      return state;
  }
};
export default reducer;
