import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./Reducers/root";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
const logger = createLogger();
const initState = {};
const store = createStore(
  rootReducer,
  initState,
  compose(
    applyMiddleware(thunk, logger),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);
export default store;
