import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown, Form, Input, Label } from "semantic-ui-react";
import Collapsable from "../Layout/Collapsable/Collapsable";
import DisplayEditor from "../PartForm/DisplayEditor/DisplayEditor";
import UploadImage from "../UI/UploadImage/UploadImage";
import Display from "../PartForm/Display/Display";
import classes from "./TypeForm.module.scss";
export default function TypeForm({
  initValue = {},
  edit,
  action = {},
  loading,
}) {
  const [name, setName] = useState(initValue.name || "");
  const [display, setDisplay] = useState(initValue.display);
  const [maxAmount, setMaxAmount] = useState(initValue.maxAmount || 1);
  const [dependsOn, setDependsOn] = useState(initValue.dependsOn || []);
  const [image, setImage] = useState();
  const { partTypes } = useSelector((state) => state.metaReducer);
  const submit = () => {
    const obj = { name, display, maxAmount, dependsOn, image };
    action.onClick(obj);
  };

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <Input
          onChange={(e) => setName(e.target.value)}
          value={name}
          label="שם"
          disabled={edit}
        />

        <Input
          onChange={(e) => setMaxAmount(e.target.value)}
          value={maxAmount}
          label="כמות מקסימלית"
        />

        <Dropdown
          options={partTypes.map((x) => ({
            text: x.name,
            value: x.id,
          }))}
          onChange={(e, { value }) => {
            setDependsOn(value);
          }}
          multiple
          value={dependsOn}
          button
          icon="chain"
          labeled
          text={dependsOn.length === 0 ? "תלוי ב..." : undefined}
          className="icon"
        />
      </div>
      <div className={classes.media}>
        <UploadImage onChange={setImage} value={initValue.image} />
        <div>
          <Label pointing="below" size="big">
            תצוגה
          </Label>
          <Display value={display} onChange={setDisplay} />
        </div>
      </div>
      <Button onClick={submit} color={action.color} loading={loading}>
        {action.text}
      </Button>
    </div>
  );
}
